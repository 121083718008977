export default [
  {
    title: "Leave Date",
    key: "LeaveDate",
    minWidth: 80,
    // sortable: true,
  },
  {
    title: "Evidence",
    key: "Evidence",
    sortable: true,
    minWidth: 80,
  },

  {
    title: "Notes",
    key: "Notes",
    // sortable: true,
    minWidth: 80,
  },
];
